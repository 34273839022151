import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  useMediaQuery,
} from "@mui/material";

//calendar
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import { SearchButton, TypographyText } from '../../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../../untils/icons.js'

//services
import { POST, TRANSACTIONPROVIDER, MERCHANT, GET } from "../../../service/service.js"
import moment from "moment-timezone";
import TableMobile from "./TableMobile.js";
import TableDesktop from "./TableDesktop.js";

function CallbackProviderList() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [transactions, setTransactions] = useState([]);
  const [broker, setBroker] = useState("All");
  const [brokerList, setBrokerList] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
  const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [parseBroker, setParseBroker] = useState(null);
  const [parsePayload, setParsePayload] = useState(null);

  useEffect(() => {
    getTransactionProvider({ merchant_name: broker, startDate, endDate });
    getBroker();
  }, []);

  const getTransactionProvider = async ({ merchant_name: broker, startDate, endDate }) => {
    try {
      const res = await POST(TRANSACTIONPROVIDER, {
        merchant_name: broker,
        startDate,
        endDate,
      });
      if (res.success) {
        const sortedData = res.result.sort((a, b) =>
          new Date(b.dateCreated) - new Date(a.dateCreated)
        );
        setTransactions(sortedData);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Error fetching transactions");
    }
  };

  const getBroker = async () => {
    try {
      const res = await GET(MERCHANT);
      if (res.success) {
        setBrokerList(res.result);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert("Error fetching brokers");
    }
  };

  const handleSearch = () => {
    getTransactionProvider({ merchant_name: broker, startDate, endDate });
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const handleRowClick = (transaction) => {
    try {
      const parsedPayload = JSON.parse(transaction.payload);
      setParseBroker(transaction.merchantName);
      setParsePayload(parsedPayload);
      setOpenDialog(true);
    } catch (error) {
      alert("Error parsing payload");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setParseBroker(null);
    setParsePayload(null);
  };

  const handleChangeStartDate = (startValue) => {
    if (startValue) {
      if (startValue.isAfter(endDate)) {
        setEndDate(startValue.add(1, "second"));
      } else if (startValue.isSame(endDate)) {
        setEndDate(startValue.add(1, "second"));
      }
      setStartDate(startValue);
    }
  };

  const handleChangeEndDate = (endValue) => {
    if (endValue) {
      if (endValue.isBefore(startDate)) {
        setStartDate(endValue.subtract(1, "second"));
      } else if (endValue.isSame(startDate)) {
        setStartDate(endValue.subtract(1, "second"));
      }
      setEndDate(endValue);
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid>
        <Box display="flex" justifyContent="center">
          <Paper sx={{ width: '90%' }} elevation={0}>
            <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
              <Box sx={{ border: { xs: 'none', sm: 1 }, borderRadius: { xs: 'none', sm: 1 }, backgroundColor: { xs: 'none', sm: 'rgba(251, 226, 241, 1)' }, borderColor: { xs: 'none', sm: 'rgba(0, 0, 0, 0.12)' }, boxShadow: { xs: 'none', sm: '0px 4px 10px rgba(0, 0, 0, 0.25)' } }}>
                <TypographyText sx={{ mb: { xs: 2, sm: 0 }, p: { xs: 0, sm: 2 }, fontSize: { xs: '20px', sm: '18px', md: '20px' }, fontWeight: 'bold' }}>
                  ค้นหา
                </TypographyText>
                <Grid item xs={12} sx={{ p: { xs: 0, sm: 2 } }}>
                  <Grid container spacing={2} marginBottom={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <InputLabel>Broker</InputLabel>
                        <Select
                          value={broker}
                          onChange={(e) => setBroker(e.target.value)}
                          label="Broker"
                          sx={{ background: 'white' }}
                        >
                          <MenuItem value="All">All</MenuItem>
                          {brokerList.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DesktopDatePicker
                        label="วันที่เริ่มต้น"
                        inputFormat="DD/MMM/YYYY HH:mm:ss"
                        value={startDate}
                        onChange={handleChangeStartDate}
                        maxDate={dayjs()}
                        renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <DesktopDatePicker
                        label="วันที่สิ้นสุด"
                        inputFormat="DD/MMM/YYYY HH:mm:ss"
                        value={endDate}
                        onChange={handleChangeEndDate}
                        minDate={startDate}
                        maxDate={dayjs()}
                        renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} sx={{ mt: 1 }}>
                      <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>
                        ค้นหา
                      </SearchButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid
                sx={{
                  color: "rgba(145, 143, 143, 1)",
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "15px"
                }}
              >
                <span>ผลค้นหา</span>
                <span>{transactions.length} รายการ</span>
              </Grid>

              {isMobile ? (
                <TableMobile
                  transactions={transactions}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  userTimeZone={userTimeZone}
                  handleRowClick={handleRowClick}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ) : (
                <TableDesktop
                  transactions={transactions}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  userTimeZone={userTimeZone}
                  handleRowClick={handleRowClick}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}

              <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogContent sx={{
                  color: "#e0e0e0",
                  backgroundColor: "#2d2f31",
                }}>
                  <pre
                    style={{
                      overflow: "auto",
                      backgroundColor: "#35373b",
                      padding: "10px",
                      color: "#b3e5fc"
                    }}>
                    <Typography>
                      <span>Broker:</span> {parseBroker}
                    </Typography>
                    {JSON.stringify(parsePayload, null, 2)}
                  </pre>
                </DialogContent>
                <DialogActions sx={{
                  backgroundColor: "#2d2f31",
                  justifyContent: "center"
                }}>
                  <Button sx={{
                    backgroundColor: "#ffeb3b",
                    color: "#292a2c",
                    "&:hover": {
                      backgroundColor: "#fdd830"
                    },
                    borderRadius: "20px",
                    padding: "8px 24px"
                  }}
                    onClick={handleCloseDialog}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </LocalizationProvider >
  );
}

export default CallbackProviderList;