import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { thousandsSeparator } from '../../untils/shortcut'

function TableDataMobile({ withdrawlist, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, userTimeZone }) {
    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {withdrawlist.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    align="center"
                                >
                                    No records to display
                                </TableCell>
                            </TableRow>
                        ) : (
                            withdrawlist
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((withdrawlist) => (
                                    <TableRow key={withdrawlist.id}>
                                        <div className="transaction-card">
                                            <p><strong>Broker Name:</strong> {withdrawlist.merchant_name}</p>
                                            <p><strong>Transaction Id:</strong> {withdrawlist.client_transaction_id}</p>
                                            <p><strong>Date:</strong> {moment(withdrawlist.date_created).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                            <p><strong>Amount:</strong> {thousandsSeparator(withdrawlist.amount_with_fee)}</p>
                                        </div>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table >
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Rows per page"
                    count={withdrawlist.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer >
        </>
    )
}

export default TableDataMobile