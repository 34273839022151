import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader.jsx';
import TransactionList from '../components/transaction/TransactionList.jsx';

function ListTransactionMerchant() {

    return (
        <>
            <Grid>
                <PageHeader title="รายการ" />
                <TransactionList />
            </Grid>
        </>
    )
}

export default ListTransactionMerchant;