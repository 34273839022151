import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination, Grid, Box } from '@mui/material';
import { thousandsSeparator } from '../../untils/shortcut';
import moment from 'moment';

function TableData({ withdrawlist, rowsPerPage, handleChangePage, handleChangeRowsPerPage, userTimeZone, page }) {

    const paginatedData = withdrawlist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Grid>
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '100%' }} elevation={0}>
                    <TableContainer component={Paper} sx={{ border: '1px solid black' }}>
                        <Table sx={{ minWidth: 900 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Broker Name</TableCell>
                                    <TableCell>Transaction Id</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.length > 0 ? (
                                    paginatedData.map((withdrawal, index) => (
                                        <TableRow key={withdrawal.id}>
                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>{withdrawal.merchant_name}</TableCell>
                                            <TableCell>{withdrawal.client_transaction_id}</TableCell>
                                            <TableCell>{moment(withdrawal.date_created).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss')}</TableCell>
                                            <TableCell>{thousandsSeparator(withdrawal.amount_with_fee)}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Typography variant="body2" color="textSecondary">
                                                No records to display
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={withdrawlist.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage="Rows per page:"
                    />
                </Paper>
            </Box>
        </Grid>
    );
}

export default TableData;