import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { thousandsSeparator } from '../../untils/shortcut'

function TableDesktop({ transactions, page, rowsPerPage, userTimeZone, handleReject, handleApprove, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <>
            <TableContainer component={Paper} sx={{ marginTop: 2, border: '1px solid black' }}>
                <Table sx={{ minWidth: 900 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '5%' }}>No.</TableCell>
                            <TableCell sx={{ width: '15%' }}>Time</TableCell>
                            <TableCell sx={{ width: '20%' }}>Client Transaction ID</TableCell>
                            <TableCell sx={{ width: '20%' }}>System Transaction ID</TableCell>
                            <TableCell sx={{ width: '15%' }}>Merchant Name</TableCell>
                            <TableCell sx={{ width: '10%' }}>Type</TableCell>
                            <TableCell sx={{ width: '10%' }}>Amount</TableCell>
                            <TableCell sx={{ width: '10%' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    No records to display
                                </TableCell>
                            </TableRow>
                        ) : (
                            transactions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((transaction, index) => (
                                    <TableRow key={transaction.id}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{moment(transaction.dateCreated).tz(userTimeZone).format("DD-MMM-YYYY HH:mm:ss")}</TableCell>
                                        <TableCell>{transaction.clientTransactionId}</TableCell>
                                        <TableCell>{transaction.systemTransactionId}</TableCell>
                                        <TableCell>{transaction.merchant.name}</TableCell>
                                        <TableCell>{transaction.Type}</TableCell>
                                        <TableCell>{thousandsSeparator(transaction.amount)}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: "50px",
                                                    backgroundColor: "rgba(79, 229, 94, 1)",
                                                    color: "white",
                                                    '&:hover': {
                                                        backgroundColor: "rgba(76, 129, 88, 1)",
                                                    },
                                                }}
                                                onClick={() => handleApprove(transaction.id)}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: "50px",
                                                    backgroundColor: "rgba(220, 53, 69, 1)",
                                                    color: "white",
                                                    '&:hover': {
                                                        backgroundColor: "rgba(148, 68, 76, 1)",
                                                    },
                                                    marginTop: '5px'
                                                }}
                                                onClick={() => handleReject(transaction.id)}
                                            >
                                                Reject
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Rows per page"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}

export default TableDesktop