import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'

function TableMobile({ transactions, page, rowsPerPage, userTimeZone, handleRowClick, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {transactions.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    align="center"
                                >
                                    No records to display
                                </TableCell>
                            </TableRow>
                        ) : (
                            transactions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((transaction) => (
                                    <TableRow key={transaction.id} hover onClick={() => handleRowClick(transaction)}>
                                        <div className="transaction-card">
                                            <p><strong>Broker:</strong> {transaction.merchantName}</p>
                                            <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                            <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                            <p><strong>Date:</strong> {moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                        </div>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table >
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Rows per page"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}

export default TableMobile