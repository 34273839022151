import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    Grid,
    Paper,
    Select,
    MenuItem,
    TextField,
    InputLabel,
    Typography,
    useMediaQuery
} from '@mui/material';

//theme
import { SaveButton } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';


//service
import { POST, GET, CREATEMERCHANT, GETPROVIDER, GETAGENT } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { isMoment } from 'moment';

function MerchantRecord() {

    const [name, setName] = useState('');
    const [agentRecord, setAgentRecord] = useState([]);
    const [agentName, setAgentName] = useState(0);
    // const [feeIn, setFeeIn] = useState(0);
    // const [feeOut, setFeeOut] = useState(0);
    const [systemFeeIn, setSystemFeeIn] = useState(0.5);
    const [systemFeeOut, setSystemFeeOut] = useState(0.5);
    const [providerFeeIn, setProviderFeeIn] = useState(0);
    const [providerFeeOut, setProviderFeeOut] = useState(0);
    const [agentFeeIn, setAgentFeeIn] = useState(0);
    const [agentFeeOut, setAgentFeeOut] = useState(0);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [provider, setProvider] = useState('ipps');
    const [indexProvider, setIndexProvider] = useState(-1);
    const [providerData, setProviderData] = useState([]);
    const [paysoMerchantId, setPaysoMerchantId] = useState(0)
    const [sumFeeIn, setSumFeeIn] = useState(0)
    const [sumFeeOut, setSumFeeOut] = useState(0)
    const [proxpayApiKey, setProxpayApiKey] = useState('')
    const [proxpayUsername, setProxpayUsername] = useState('')
    const [proxpayPassword, setProxpayPassword] = useState('')
    const [paysoApiToken, setPaysoApiToken] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        getProvider()
        getAgent()
    }, []);

    useEffect(() => {
        setSumFeeIn(Number(providerFeeIn) + Number(agentFeeIn) + Number(systemFeeIn))
        setSumFeeOut(Number(providerFeeOut) + Number(agentFeeOut) + Number(systemFeeOut))
    }, [agentFeeIn, agentFeeOut, indexProvider, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut]);

    const getProvider = async () => {

        try {
            let res = await GET(GETPROVIDER);
            if (res.success) {
                setProviderData(res.result)
                // setSumFeeIn(res.result[0].fee.feeAmount + Number(systemFeeIn))
                // setSumFeeOut(res.result[0].fee.feeAmount + Number(systemFeeOut))
            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }

    }
    const getAgent = async () => {
        try {
            let res = await GET(GETAGENT);
            if (res.success) {
                setAgentRecord(res.result)
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }



    const createMerchant = async () => {
        try {
            if (password != confirmPassword) {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเช็ค Password`,
                    confirmButtonText: 'ตกลง',
                })
                return;
            }
            if (indexProvider == -1) {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเลือก Provider`,
                    confirmButtonText: 'ตกลง',
                })
                return;
            }
            let res = await POST(CREATEMERCHANT, { name, username, password, provider, proxpayUsername, proxpayPassword, proxpayApiKey, paysoApiToken, paysoMerchantId, agentId: agentName, agentFeeIn, agentFeeOut, systemFeeIn, systemFeeOut, providerFeeIn, providerFeeOut });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Merchant");
                    }
                })

            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message.message}`,
                        confirmButtonText: 'ตกลง',
                    })
                }

            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const handleSetProvider = async (value) => {
        if (value === -1) {
            setIndexProvider(-1);
            setProvider('');
            setStatus('');
            setProviderFeeIn('');
            setProviderFeeOut('');
            return;
        }

        if (providerData && providerData[value]) {
            const selectedProvider = providerData[value];
            setIndexProvider(value);
            setProvider(selectedProvider.name);
            setStatus(selectedProvider.status);
            setProviderFeeIn(selectedProvider.fee.feeAmount);
            setProviderFeeOut(selectedProvider.fee.feeAmount);
        } else {
            console.error("Invalid provider data for the selected index.");
        }
    };


    const handleSetAgent = async (value) => {
        if (value == 0) {
            setAgentFeeIn(0)
            setAgentFeeOut(0)
        }
        setAgentName(value)

    }

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Grid>
            <PageHeader title="เพิ่มร้านค้า" buttonBack={true} />
            <>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '100%' }} elevation={0}>
                        <Grid item xs={12} sx={{ p: 5, border: 1, borderColor: 'rgba(0, 0, 0, 0.12)', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)' }}>
                            <Typography sx={{ fontSize: { xs: '18px', sm: '20px', md: '28px' }, my: 2, fontWeight: 'bold', textAlign: 'center', color: 'rgba(20, 41, 70, 1)' }}>เพิ่มข้อมูลร้านค้า</Typography>
                            <Box sx={{ p: 1, border: { xs: 'none', sm: 1 }, backgroundColor: { xs: 'none', sm: 'rgba(251, 226, 241, 1)' }, borderColor: { xs: 'none', sm: 'rgba(0, 0, 0, 0.12)' }, boxShadow: { xs: 'none', sm: '0px 4px 10px rgba(0, 0, 0, 0.25)' } }}>
                                <Grid item xs={12} sx={{ p: { xs: 0, sm: 5 } }}>
                                    <Grid container direction="row" spacing={4}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Grid container direction="row" spacing={4}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="กรอกชื่อร้านค้า"
                                                        value={name}
                                                        onChange={(e) => setName(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='ชื่อร้าน'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl sx={{ backgroundColor: 'white' }} fullWidth>
                                                        <InputLabel id="demo-simple-select-autowidth-label">ผู้ให้บริการ</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-autowidth-label"
                                                            id="demo-simple-select-autowidth"
                                                            value={indexProvider}
                                                            onChange={(event) => handleSetProvider(event.target.value)}
                                                            autoWidth
                                                            label="ผู้ให้บริการ"
                                                        >
                                                            <MenuItem value={-1}>เลือกผู้ให้บริการ</MenuItem>
                                                            {providerData.map((e, i) => {
                                                                return <MenuItem value={i}>{e.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <FormControl sx={{ backgroundColor: 'white' }} fullWidth>
                                                        <InputLabel id="demo-simple-select-autowidth-label">Agent</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-autowidth-label"
                                                            id="demo-simple-select-autowidth"
                                                            value={agentName}
                                                            onChange={(event) => handleSetAgent(event.target.value)}
                                                            autoWidth
                                                            label="Agent"
                                                        >
                                                            <MenuItem value={0}>เลือก Agent</MenuItem>
                                                            {agentRecord.map((e, i) => {
                                                                return <MenuItem value={e.id}>{e.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic" type="email"
                                                        label="กรอก Email username"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.currentTarget.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        autoComplete="email"
                                                        error={Boolean(username && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(username))}
                                                        helperText={
                                                            username && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(username)
                                                                ? "กรุณากรอกอีเมลที่ถูกต้อง"
                                                                : ""
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="กรอก password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="ยืนยัน password"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>

                                                {status === 1 ? (
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Grid container direction="row" spacing={4}>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <TextField
                                                                    sx={{ backgroundColor: 'white' }}
                                                                    id="outlined-basic"
                                                                    label={
                                                                        provider === 'payso'
                                                                            ? 'Paysolution Merchant id'
                                                                            : provider === 'habito'
                                                                                ? 'habito Merchant id'
                                                                                : 'API Token'
                                                                    }
                                                                    value={paysoMerchantId}
                                                                    onChange={(e) => setPaysoMerchantId(e.currentTarget.value)}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <TextField
                                                                    sx={{ backgroundColor: 'white' }}
                                                                    id="outlined-basic"
                                                                    label="Proxpay Username"
                                                                    value={proxpayUsername}
                                                                    onChange={(e) => setProxpayUsername(e.currentTarget.value)}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <TextField
                                                                    sx={{ backgroundColor: 'white' }}
                                                                    id="outlined-basic"
                                                                    label="Proxpay Password"
                                                                    value={proxpayPassword}
                                                                    onChange={(e) => setProxpayPassword(e.currentTarget.value)}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <TextField
                                                                    sx={{ backgroundColor: 'white' }}
                                                                    id="outlined-basic"
                                                                    label="Proxpay Api Key"
                                                                    value={proxpayApiKey}
                                                                    onChange={(e) => setProxpayApiKey(e.currentTarget.value)}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <Grid container direction="row" spacing={4}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="Provider Fee In"
                                                        type="number"
                                                        value={providerFeeIn}
                                                        onChange={(e) => setProviderFeeIn(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="Provider Fee Out"
                                                        type="number"
                                                        value={providerFeeOut}
                                                        onChange={(e) => setProviderFeeOut(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="System Fee In"
                                                        type="number"
                                                        value={systemFeeIn}
                                                        onChange={(e) => setSystemFeeIn(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='AB0000'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        id="outlined-basic"
                                                        label="System Fee Out"
                                                        type="number"
                                                        value={systemFeeOut}
                                                        onChange={(e) => setSystemFeeOut(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='1000'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        disabled={agentName === 0 ? true : false}
                                                        id="outlined-basic"
                                                        label="Agent Fee In"
                                                        type="number"
                                                        value={agentFeeIn}
                                                        onChange={(e) => setAgentFeeIn(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        disabled={agentName === 0 ? true : false}
                                                        id="outlined-basic"
                                                        label="Agent Fee Out"
                                                        type="number"
                                                        value={agentFeeOut}
                                                        onChange={(e) => setAgentFeeOut(e.currentTarget.value)}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        disabled
                                                        id="outlined-basic"
                                                        label="Sum Fee In"
                                                        type="number"
                                                        value={sumFeeIn}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        sx={{ backgroundColor: 'white' }}
                                                        disabled
                                                        id="outlined-basic"
                                                        label="Sum Fee Out"
                                                        type="number"
                                                        value={sumFeeOut}
                                                        variant="outlined"
                                                        placeholder='0.1'
                                                        required
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        sx={{
                                                            display: provider === 'ipps' ? 'none' : 'block',
                                                            backgroundColor: 'white',
                                                            height: '100%',
                                                            '& .MuiInputBase-root': {
                                                                height: '100%',
                                                            },
                                                        }}
                                                        id="outlined-basic"
                                                        label={
                                                            provider === 'payso'
                                                                ? 'Paysolution API Token'
                                                                : provider === 'habito'
                                                                    ? 'habito API Token'
                                                                    : 'API Token'
                                                        }
                                                        value={paysoApiToken}
                                                        onChange={(e) => setPaysoApiToken(e.currentTarget.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        multiline
                                                        rows={isMobile && status === 1 ? 16 : status === 1 ? 16 : 1}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                                            <Box className='saveButton' display="flex" justifyContent="center" alignItems="center" textAlign="center">
                                                <SaveButton fullWidth variant="outlined" onClick={() => createMerchant()} startIcon={<UnarchiveIcon />}>
                                                    ส่งออกข้อมูล
                                                </SaveButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Paper>
                </Box >
            </>
        </Grid>
    )
}

export default MerchantRecord;