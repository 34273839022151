import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import CallbackProviderList from '../components/callbacklog/callbackProvider/CallbackProviderList'

function CallbackProviderlist() {

    return (
        <>
            <Grid >
                <PageHeader title="CallbackProvider" path="" />
                <CallbackProviderList />
            </Grid>
        </>
    )
}

export default CallbackProviderlist;