import React, { useEffect, useState, useMemo } from 'react'
import {
    Box,
    Grid,
    Paper,
    CardContent,
    CardMedia,
    Card,
    CardActionArea,
    CardActions,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    Stack,
    TablePagination,
    Divider,
    useMediaQuery,
    TableContainer,
    Table,
    TableBody,
    TableRow,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { MaterialReactTable } from 'material-react-table';

import { thousandsSeparator } from '../../untils/shortcut'
import moment from 'moment-timezone';
import { ApproveIcon } from '../../untils/icons';

//untils
import { TelIcon } from '../../untils/icons';

//service
import { POST, GET, SETTLEMENTAPI, APPROVESETTLE, ip_image, DELETECUSTOMER } from '../../service/service';

//theme

//alert
import Swal from 'sweetalert2'

function SettlementTable() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'merchant.name',
                header: 'Merchant Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'date',
                header: 'Date',
                size: 50,
                accessorFn: (e) => moment(e.date).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss'),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'amount',
                header: 'money',
                inputType: 'number',
                accessorFn: (e) => (thousandsSeparator(e.amount))
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },

        ],
        [],
    );

    useEffect(() => {
        getSettlement();
    }, []);

    const getSettlement = async () => {
        try {
            let res = await GET(SETTLEMENTAPI);
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    new Date(b.date) - new Date(a.date)
                );
                setData(sortedData);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }

        } catch (error) {
            alert(error);
        }
    }


    const approveSettle = async (id) => {
        try {
            let res = await POST(APPROVESETTLE, { id });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const approveAlert = (id) => {
        Swal.fire({
            title: 'คุณต้องการยืนยันหรือไม่?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                approveSettle(id);
            }
        })
    }

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {/* List Card */}
            <Box sx={{ py: 1, pb: 5, width: "100%", display: "flex", justifyContent: "center" }}>
                <Paper sx={{ width: '90%', py: 2 }} elevation={0}>

                    <Grid
                        sx={{
                            color: "rgba(145, 143, 143, 1)",
                            display: { xs: "flex", sm: "none" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px"
                        }}
                    >
                        <span>ผลค้นหา</span>
                        <span>{data.length} รายการ</span>
                    </Grid>

                    {isMobile ? (
                        data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((column, index) => (
                                <TableContainer key={index}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <div className="transaction-card">
                                                    <p><strong>#:</strong> {index + 1 ?? ""}</p>
                                                    <p><strong>Merchant Name:</strong> {column.merchant.name ?? ""}</p>
                                                    <p><strong>Date:</strong> {moment(column.merchant.date).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss') ?? ""}</p>
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <p><strong>Money:</strong> {thousandsSeparator((column.amount ?? 0))}</p>

                                                        <IconButton
                                                            sx={{
                                                                width: "60px",
                                                                borderRadius: "20px",
                                                                color: "white",
                                                            }}
                                                            onClick={() => approveAlert(column.id)}
                                                        >
                                                            <ApproveIcon />
                                                        </IconButton>
                                                    </Box>
                                                </div>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))
                    ) : (
                        <MaterialReactTable
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 10,
                                },
                            }}
                            columns={columns}
                            data={data}
                            enableColumnOrdering
                            enableEditing={false}
                            enableRowNumbers
                            rowNumberMode="original"
                            enableRowActions
                            renderRowActions={({ row }) => (
                                <Box textAlign={"center"}>
                                    <IconButton onClick={() => approveAlert(row.original.id)}>
                                        <ApproveIcon />
                                    </IconButton>
                                </Box>
                            )}
                            muiTablePaperProps={{
                                sx: {
                                    border: '1px solid black',
                                },
                            }}
                        />
                    )}
                    <TablePagination sx={{ display: { xs: 'unset', sm: 'none', md: 'none' } }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </>
    )
}

export default SettlementTable;