import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { MaterialReactTable } from 'material-react-table';

import moment from 'moment-timezone';

//service
import { GET, GETAGENT } from '../../service/service';
import { thousandsSeparator } from '../../untils/shortcut.js'
import AgentRecord from './AgentRecord.jsx';
import AgentRecordMobile from './AgnetRecordMoblie.jsx';
import { SaveButton, SearchButton } from '../../Theme.jsx';
import { useLocation, useNavigate } from 'react-router-dom';


const AgentList = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAgent = async () => {
        try {
            let res = await GET(GETAGENT);
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    new Date(b.dateCreated) - new Date(a.dateCreated)
                );
                setData(sortedData);
            } else {
                if (res.statusCode == 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            alert("error", error);
        }
    };




    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'name',
                header: 'Agent Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'balance',
                header: 'Balance',
                size: 25,
                accessorFn: (e) => thousandsSeparator(e.balance),
                Cell: row => <div style={{ textAlign: "left" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'totalBalance',
                header: 'Total Balance',
                size: 25,
                accessorFn: (e) => thousandsSeparator(e.totalBalance),
                Cell: row => <div style={{ textAlign: "left" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'dateCreated',
                header: 'Date Create',
                size: 50,
                accessorFn: (e) => moment(e.dateCreated).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss'),
            },

        ],
        [],
    );

    useEffect(() => {
        getAgent();
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const handleAddAgent = () => {
        navigate('/Agent/AgentRecord');
    };

    const handleWithDrawAgent = () => {
        navigate('');
    };

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {/* ค้นหา */}
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '90%', py: 3 }} elevation={0}>
                    <Box sx={{ display: location.pathname === "/Agent/AgentRecord" ? "unset" : { xs: "none", sm: "none", md: "none" } }}>
                        <AgentRecord />
                    </Box>
                    <Box sx={{ display: location.pathname === "/Agent/AgentRecord" ? "unset" : { xs: "none", sm: "unset", md: "unset" } }}>
                        <AgentRecordMobile />
                    </Box>

                    <Box sx={{ p: 1, display: { xs: "flex", sm: "none", md: "none" }, justifyContent: "center" }}>
                        <ButtonGroup sx={{ width: '100%' }}>
                            <SearchButton width="auto" variant="outlined" onClick={handleWithDrawAgent} sx={{ flex: 1, p: 2 }}>
                                Withdraw Agent
                            </SearchButton>
                            <SearchButton width="auto" variant="outlined" onClick={handleAddAgent} sx={{ flex: 1, p: 2 }}>
                                + Agent
                            </SearchButton>
                        </ButtonGroup>
                    </Box>

                    <Grid
                        sx={{
                            color: "rgba(145, 143, 143, 1)",
                            display: { xs: "flex", sm: "none" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px"
                        }}
                    >
                        <span>ผลค้นหา</span>
                        <span>{data.length} รายการ</span>
                    </Grid>

                    <Grid item xs={12} sx={{ justifyContent: "flex-end", textAlign: "right", display: "flex" }}>
                        <SaveButton variant="contained" onClick={handleWithDrawAgent} sx={{ display: { xs: "none", sm: "unset", md: "unset" }, width: 200, mb: 2 }} >Withdraw Agent</SaveButton>
                    </Grid>

                    {isMobile ? (
                        data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((column, index) => (
                                <TableContainer key={index}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <div className="transaction-card">
                                                    <p><strong>Agent Name:</strong> {column.name ?? ""}</p>
                                                    <p><strong>Balance:</strong> {thousandsSeparator(column.balance) ?? ""}</p>
                                                    <p><strong>Total Balance:</strong> {thousandsSeparator(column.totalBalance) ?? ""}</p>
                                                    <p><strong>Date Create:</strong> {moment(column.dateCreated).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss') ?? ""}</p>
                                                </div>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))
                    ) : (

                        <MaterialReactTable
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 10,
                                },
                            }}
                            columns={columns}
                            data={data}
                            enableColumnOrdering
                            enableEditing={false}
                            enableRowNumbers
                            rowNumberMode="original"
                            muiTablePaperProps={{
                                sx: {
                                    border: '1px solid black',
                                },
                            }}
                        />
                    )}
                    <TablePagination sx={{ display: { xs: 'unset', sm: 'none', md: 'none' } }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper >
            </Box >
        </>
    )
}

export default AgentList;