import React from "react";
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { thousandsSeparator } from "../../../untils/shortcut";
import moment from "moment-timezone";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function ReportDepositMobile({ transactions, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, getStatusColor, getStatusBackground }) {
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {transactions.length === 0 ? (
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                align="center"
                            >
                                No records to display
                            </TableCell>
                        </TableRow>
                    ) : (
                        transactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <div className="transaction-card">
                                        <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                        <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                        <p><strong>Merchant Name:</strong> {transaction.merchant.name}</p>
                                        <p><strong>Amount:</strong> {thousandsSeparator(transaction.amount)}</p>
                                        <p><strong>Amount With Fee:</strong> {thousandsSeparator(transaction.amountWithFee)}</p>
                                        <p><strong>Date Created:</strong> {moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                        <p><strong>Date Updated:</strong> {moment(transaction.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                        <p style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <p><strong>Status : </strong> <span style={{ backgroundColor: getStatusBackground(transaction.status), borderRadius: '15px', display: 'inline-block', padding: '5px 15px', color: getStatusColor(transaction.status), fontWeight: 'bold' }}>{transaction.status}</span></p>
                                        </p>
                                    </div>
                                </TableRow>
                            ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows per page"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer >
    )
};

export default ReportDepositMobile;