import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    useMediaQuery
} from "@mui/material";


//calendar
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//services
import { POST, GET, MERCHANT, TRANSACTIONS, TRANSACTIONSAPPROVE, TRANSACTIONSREJECT } from "../../service/service.js"

//theme
import { SearchButton, TypographyText } from '../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../untils/icons.js'
import { thousandsSeparator } from "../../untils/shortcut.js";
import moment from "moment-timezone";
import TableMobile from "./TableMobile.js";
import TableDesktop from "./TableDesktop.js";

function Transaction() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isMobile = useMediaQuery('(max-width:600px)');

    const [transactions, setTransactions] = useState([]);
    const [merchant, setMerchat] = useState("All");
    const [merchantList, setMerchatList] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf("day").tz(userTimeZone));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getTransaction({ merchant_name: merchant, startDate: startDate, endDate: endDate })
        getMerchant();
    }, []);

    const getTransaction = async ({ merchant_name, startDate, endDate }) => {
        try {
            const res = await POST(TRANSACTIONS, {
                merchant_name: merchant_name,
                startDate: startDate,
                endDate: endDate
            })
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    new Date(b.dateCreated) - new Date(a.dateCreated)
                );
                setTransactions(sortedData)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setMerchatList(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const handleApprove = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to approve this transaction?");
        if (isConfirmed) {
            try {
                const res = await POST(TRANSACTIONSAPPROVE, { id: id });
                if (res.success) {
                    alert("Transaction approved successfully!");
                    getTransaction({ startDate, endDate, merchant_name: merchant });
                } else {
                    alert(res.message);
                }
            } catch (error) {
                alert("Error: " + error);
            }
        }
    };

    const handleReject = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to reject this transaction?");
        if (isConfirmed) {
            try {
                const res = await POST(TRANSACTIONSREJECT, { id: id });
                if (res.success) {
                    alert("Transaction rejected successfully!");
                    getTransaction({ startDate, endDate, merchant_name: merchant });
                } else {
                    alert(res.message);
                }
            } catch (error) {
                alert("Error: " + error);
            }
        }
    };

    const handleSearch = () => {
        getTransaction({ startDate, endDate, merchant_name: merchant })
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '90%' }} elevation={0}>
                        <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
                            <Box sx={{ border: { xs: 'none', sm: 1 }, borderRadius: { xs: 'none', sm: 1 }, backgroundColor: { xs: 'none', sm: 'rgba(251, 226, 241, 1)' }, borderColor: { xs: 'none', sm: 'rgba(0, 0, 0, 0.12)' }, boxShadow: { xs: 'none', sm: '0px 4px 10px rgba(0, 0, 0, 0.25)' } }}>
                                <TypographyText sx={{ mb: { xs: 2, sm: 0 }, p: { xs: 0, sm: 2 }, fontSize: { xs: '20px', sm: '18px', md: '20px' }, fontWeight: 'bold' }}>
                                    ค้นหา
                                </TypographyText>
                                <Grid item xs={12} sx={{ p: { xs: 0, sm: 2 } }}>
                                    <Grid container spacing={2} marginBottom={2} alignItems="center">
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel>ร้านค้า</InputLabel>
                                                <Select
                                                    sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                                                    value={merchant}
                                                    onChange={(e) => setMerchat(e.target.value)}
                                                    label='Broker'
                                                >
                                                    <MenuItem value={"All"}>{"All"}</MenuItem>
                                                    {merchantList.map((item) => (
                                                        <MenuItem key={item.name} value={item.name}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DesktopDatePicker
                                                label="วันที่เริ่มต้น"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <DesktopDatePicker
                                                label="วันที่สิ้นสุด"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                                minDate={startDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} sx={{ mt: 1 }}>
                                            <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Grid
                                sx={{
                                    color: "rgba(145, 143, 143, 1)",
                                    display: { xs: "flex", sm: "none" },
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "15px",
                                }}
                            >
                                <span>ผลค้นหา</span>
                                <span>{transactions.length} รายการ</span>
                            </Grid>

                            {isMobile ? (
                                <TableMobile
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    userTimeZone={userTimeZone}
                                    handleReject={handleReject}
                                    handleApprove={handleApprove}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            ) : (
                                <TableDesktop
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    userTimeZone={userTimeZone}
                                    handleReject={handleReject}
                                    handleApprove={handleApprove}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Box>
            </Grid>
        </LocalizationProvider >
    );
};

export default Transaction;
