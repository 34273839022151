import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    CssBaseline,
    Toolbar,
    List,
    Typography,
    Divider,
    ListItemButton,
    ListItem,
    ListItemText,
    Grid,
    IconButton,
    ListItemIcon,
    Collapse,
    Avatar,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

import {
    DrawerIcon,
    MenuDrawerIcon,
    ChevronLeftDrawerIcon,
    ChevronRightDrawerIcon,
    ListDrawerIcon,
    RecordDrawerIcon,
    BorrowDrawerIcon,
    StoreDrawerIcon,
    CustomerDrawerIcon,
    EmployeeDrawerIcon,
    SummarizeDrawerIcon,
    AgentIcon,
    LogoutDrawerIcon,
    ResetIcon,
    TransferIcon,
    StyleReceiptIcon,
    StyleExpandMoreIcon,
    StyleExpandLessIcon,
    EventAvailableIcons,
    ArrowRightIcons,
    AccountWalletIcon,
    Leaderboard,
    AssuredWorkload,
    Article,
    DesktopWindows,
    PermIdentity,
} from '../untils/icons.js';

import { useLocation } from "react-router-dom";

//theme
import {
    themeDrawer,
    Main,
    AppBar,
    DrawerHeader,
    drawerWidth,
    CssDrawer,
} from '../Theme.jsx';

//service
import { Stack } from '@mui/system';

function CustomDrawer({ children }) {
    const isMobile = useMediaQuery("(max-width:599px)");

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [openCallbackLog, setOpenCallbackLog] = useState(false);
    const [openReportLog, setOpenReportLog] = useState(false);

    const location = useLocation();
    const currentPath = location.pathname;
    const isActive = (path) => currentPath === path;

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleCallbackLogClick = () => {
        setOpenCallbackLog((prev) => !prev);
    };

    const handleReportLogClick = () => {
        setOpenReportLog((prev) => !prev);
    };

    const handleRoute = (path) => {
        return () => {
            setOpen(false);
            navigate(path);
        };
    };

    const Logout = () => {
        // remove the token and user from the session storage
        localStorage.removeItem('token_Login');
        localStorage.removeItem('username');
        window.location.replace("/");
    }

    // const getUserByID = async () => {

    //     try {
    //         let res = await POST(GETUSERBYID, { user_id: localStorage.getItem("user_id") });
    //         if (res.success) {
    //             setUserData(res.result);
    //         } else {
    //             alert(res.message);
    //         }

    //     } catch (error) {
    //         alert(error);
    //     }
    // }

    // const Logout = async () => {


    //     try {
    //         if (localStorage.getItem("token_Login")) {

    //             let signout = await POST(logout, localStorage.getItem("token_Login"));
    //             if (signout.success) {
    //                 window.location.replace("Login");
    //             } else {
    //                 alert(signout.message);
    //             }
    //         }
    //     } catch (error) {
    //         alert(error);
    //     }

    // };

    // useEffect(() => {
    //     getUserByID();
    // }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar >
                    <Grid container columns={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ p: 1, alignItems: "center" }}>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuDrawerIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid xs={10} sx={{ display: 'flex', justifyContent: "center" }}>
                            <img style={{ width: "150px", marginTop: "15px" }}
                                src="https://www.happypay.asia/logo.svg"
                                alt="Happy Pay Logo"
                            />
                        </Grid>
                        {/* <Grid xs={1.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ backgroundColor: "#1870C8", borderRadius: "30%", width: "100px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="caption" noWrap component="div" fontSize={16}>
                                    {localStorage.getItem("username")}
                                </Typography>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: 'rgba(20, 41, 70, 1)'
                    },
                }}
                // variant="persistent"
                variant="temporary"
                anchor="left"
                open={open}
            >
                <Toolbar sx={{ mt: 3, mb: 2 }}>
                    <List>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <Stack direction="row">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 4 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Avatar sx={{ width: 50, height: 50 }}>{localStorage.getItem("username")[0]}</Avatar>
                                </ListItemIcon>
                                <Grid container columns={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                    <Grid xs={11} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <Stack>
                                                <Typography sx={{ display: "flex", justifyContent: "flex-start", color: "rgba(241, 158, 220, 1)" }} fontSize={16}>
                                                    Username
                                                </Typography>
                                                <Typography sx={{ display: "flex", justifyContent: "flex-end", marginLeft: 2, color: "white" }} fontSize={18}>
                                                    {localStorage.getItem("username")}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <DrawerHeader>
                                            <IconButton onClick={handleDrawerClose} sx={{ ml: 1, color: "white" }}>
                                                {theme.direction === 'ltr' ? <ChevronLeftDrawerIcon /> : <ChevronRightDrawerIcon />}
                                            </IconButton>
                                        </DrawerHeader>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </ListItem>
                    </List>

                </Toolbar>
                <Divider />

                <List>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={handleRoute("/")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <ListDrawerIcon fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="รายการ" />
                        </ListItemButton>
                    </ListItem>



                    {/* <ListItem disablePadding onClick={handleRoute("/Borrow")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <BorrowDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="สินค้ายืม" />
                        </ListItemButton>
                    </ListItem> */}

                    <ListItem disablePadding onClick={handleRoute("/Merchant")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/Merchant") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/Merchant") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <Leaderboard fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="ข้อมูลร้านค้า" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Settlement")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/Settlement") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/Settlement") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <AssuredWorkload fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="Settlement" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Agent")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/Agent") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/Agent") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <AgentIcon fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="Agent" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/Transaction")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/Transaction") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/Transaction") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <EventAvailableIcons fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="Transaction" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleCallbackLogClick}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                color: openCallbackLog ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: openCallbackLog ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)",
                                },
                            }}
                        >
                            <Article fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }} primary="CallbackLog" />
                            {openCallbackLog ? <StyleExpandLessIcon /> : <StyleExpandMoreIcon />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openCallbackLog}>
                        <List component="div" disablePadding>
                            <ListItem disablePadding onClick={handleRoute("/CallbackProvider")}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        pl: 4,
                                        color: "rgba(20, 41, 70, 1)",
                                        backgroundColor: "rgba(255, 242, 250, 1)",
                                        ":hover": {
                                            backgroundColor: "rgba(255, 242, 250, 1)",
                                            color: "rgba(20, 41, 70, 1)"
                                        }
                                    }}
                                >
                                    <ArrowRightIcons fontSize='small' />
                                    <ListItemText primary="Callback Provider" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={handleRoute("/CallbackMerchant")}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        pl: 4,
                                        color: "rgba(20, 41, 70, 1)",
                                        backgroundColor: "rgba(255, 242, 250, 1)",
                                        ":hover": {
                                            backgroundColor: "rgba(255, 242, 250, 1)",
                                            color: "rgba(20, 41, 70, 1)"
                                        }
                                    }}
                                >
                                    <ArrowRightIcons fontSize='small' />
                                    <ListItemText primary="Callback Merchant" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem disablePadding onClick={handleReportLogClick}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                color: openReportLog ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: openReportLog ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)",
                                },
                            }}
                        >
                            <DesktopWindows fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }} primary="Report" />
                            {openReportLog ? <StyleExpandLessIcon /> : <StyleExpandMoreIcon />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openReportLog}>
                        <List component="div" disablePadding>
                            <ListItem disablePadding onClick={handleRoute("/ReportWithdraw")}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        pl: 4,
                                        color: "rgba(20, 41, 70, 1)",
                                        backgroundColor: "rgba(255, 242, 250, 1)",
                                        ":hover": {
                                            backgroundColor: "rgba(255, 242, 250, 1)",
                                            color: "rgba(20, 41, 70, 1)"
                                        }
                                    }}
                                >
                                    <ArrowRightIcons fontSize='small' />
                                    <ListItemText primary="Report Withdraw" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={handleRoute("/ReportDeposit")}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        pl: 4,
                                        color: "rgba(20, 41, 70, 1)",
                                        backgroundColor: "rgba(255, 242, 250, 1)",
                                        ":hover": {
                                            backgroundColor: "rgba(255, 242, 250, 1)",
                                            color: "rgba(20, 41, 70, 1)"
                                        }
                                    }}
                                >
                                    <ArrowRightIcons fontSize='small' />
                                    <ListItemText primary="Report Deposit" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem disablePadding onClick={handleRoute("/WithdrawBroker")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/WithdrawBroker") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/WithdrawBroker") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <AccountWalletIcon fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="Withdraw Broker" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={handleRoute("/User")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/User") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/User") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <PermIdentity fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="ข้อมูลพนักงาน" />
                        </ListItemButton>
                    </ListItem>

                    {/* <ListItem disablePadding onClick={handleRoute("/Summary")}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 2 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <SummarizeDrawerIcon fontSize='medium' />
                            </ListItemIcon>
                            <ListItemText
                                primary="สรุปข้อมูล" />
                        </ListItemButton>
                    </ListItem> */}

                    <ListItem disablePadding onClick={handleRoute(`/ResetPassword`)}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: isActive("/ResetPassword") ? "rgba(20, 41, 70, 1)" : "white",
                                backgroundColor: isActive("/ResetPassword") ? "rgba(249, 224, 239, 1)" : "transparent",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <ResetIcon fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="เปลี่ยนรหัสผ่าน" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding onClick={Logout}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                color: "white",
                                ":hover": {
                                    backgroundColor: "rgba(249, 224, 239, 1)",
                                    color: "rgba(20, 41, 70, 1)"
                                }
                            }}
                        >
                            <LogoutDrawerIcon fontSize='medium' />
                            <ListItemText sx={{ marginLeft: 2 }}
                                primary="ออกจากระบบ" />
                        </ListItemButton>
                    </ListItem>
                </List>

            </Drawer >
            <Main open={open}>
                <DrawerHeader />
                <Grid container sx={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {children}
                    </Grid>
                </Grid>
            </Main>
        </Box >
    );
}

export default CustomDrawer;
