import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {
  Button,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
}
  from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';


export const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: "rgba(202, 109, 229, 1)",
  borderColor: "rgba(202, 109, 229, 1)",
  color: '#fff',
  fontSize: 16,
  fontWeight: "bold",
  padding: "8px 16px",
  transition: "all 0.3s ease-in-out",

  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    padding: "6px 12px",
  },

  [theme.breakpoints.down("xs")]: {
    fontSize: 12,
    padding: "4px 10px",
  },

  '&:hover': {
    backgroundColor: "rgba(129, 42, 154, 1)",
    borderColor: "rgba(129, 42, 154, 1)",
    fontSize: 16,
    fontWeight: "bold",
  },
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  width: "100%",
  borderRadius: 10,
  color: "#fff",
  borderColor: "rgba(202, 109, 229, 1)",
  backgroundColor: "rgba(202, 109, 229, 1)",
  fontSize: 16,
  fontWeight: "bold",

  "&:hover": {
    borderRadius: 10,
    backgroundColor: "rgba(129, 42, 154, 1)",
    borderColor: "rgba(129, 42, 154, 1)",
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },

  [theme.breakpoints.down("sm")]: {
    color: "white",
    backgroundColor: "rgba(202, 109, 229, 1)",

    "&:hover": {
      color: "rgba(202, 109, 229, 1)",
      borderColor: "rgba(202, 109, 229, 1)",
      backgroundColor: "white",
    },
  },
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
  color: 'rgba(20, 41, 70, 1)',
  fontSize: 20,
  fontWeight: "bold",
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  fontWeight: "bold",
}));

export const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffac20",
  '&:hover': {
    backgroundColor: "#f59a00",
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 30,
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.main,
  }
}));

export const themeDrawer = createTheme({
  palette: {
    primary: {
      main: "#54BCDD",
    },
  },
});

export const CssDrawer = {
  '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
    backgroundColor: "#54BCDD",
  },
};

// export const CssTextField = {
//   '& label.Mui-focused': {
//     color: '#000000',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: '#93B7CD',
//     },
//     '&:hover fieldset': {
//       borderColor: '#93B7CD',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#93B7CD',
//     },
//   },
// };

function Theme({ children }) {
  return <ThemeProvider theme={mdTheme}>{children}</ThemeProvider>;
}
export default Theme;

const mdTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // backgroundImage: 'rgb(11,79,131)',
          // backgroundImage: 'linear-gradient(0deg, rgba(18, 27, 85, 1) 0%,rgba(181,200,251,1) 80%, rgba(51, 75, 143, 1) 99%)'
          // backgroundImage: "linear-gradient(270deg, rgba(171,164,227,1) 0%, rgba(245,244,255,1) 4%, rgba(255,255,255,1) 11%, rgba(255,255,255,1) 87%, rgba(245,244,255,1) 96%, rgba(171,164,227,1) 100%)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#1E1A31"
        }
      }
    },
    // MuiDialog: {
    //   styleOverrides: {
    //     paper: {
    //       border: "solid 1px #1E1A31",
    //       background: "#1E1A31"
    //     }
    //   }
    // }
  },
  palette: {
    // mode: "dark",
    // primary: {
    //   main: "#1C65D2",
    //   light: "#E6E5F3",
    //   dark: "#13488F",
    //   contrastText: "#fff"
    // },
    secondary: {
      main: "#A5A8D5",
      // light: "#EEE",
      light: "#fff",
      dark: "#A5A8D5",
      contrastText: "#0B1A4A",
    },
    // colorful: {
    //   main: {
    //     backgroundImage:
    //       "linear-gradient(to right, rgb(1 134 218), rgb(182 49 167))",
    //     color: "#fff"
    //   }
    // },
    // text: {
    //   primary: "#efefef"
    // },
    // divider: "#A4A5CD",
    divider: "#7F7F7F",
    background: {
      // default: "rgb(22, 19, 36)",
      // default: "#021028",
      // default: "linear-gradient(135deg, rgba(1,134,218,1) 0%, rgba(23,20,37,1) 75%)",
      // paper: "rgba(255,255,255,0.80)",
      // paper: "rgba(51,75,142,0.5)",
      main: "#fff",
    },

  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0b1a4a",
    color: theme.palette.common.white,
    fontSize: 16,
    padding: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    padding: "6px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#fff",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#fff",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1,
  },
}));

export const drawerWidth = 300;
export const MobileWidth = 200;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    minHeight: "100vh",
    maxHeight: "100%",
    width: "100%",
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: "rgba(241, 158, 220, 1)",
  color: theme.palette.secondary.contrastText,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const commonStylesUserList = {
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '80%',
  height: 'auto',
  borderTopLeftRadius: '16px',
  borderBottomLeftRadius: '16px',
  borderLeft: 5,
  borderLeftColor: "#4a148c",
  boxShadow: 5,
};

export const commonStylesUserListMobile = {
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '80%',
  height: 'auto',
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#4a148c",
  borderRightColor: "#4a148c",
  boxShadow: 5,
};

export const commonStylesCarList = {
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '80%',
  height: 'auto',
  borderTopLeftRadius: '16px',
  borderBottomLeftRadius: '16px',
  borderLeft: 5,
  borderLeftColor: "#ffac20",
  boxShadow: 5,
};

export const commonStylesCarListMobile = {
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '80%',
  height: 'auto',
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#ffac20",
  borderRightColor: "#ffac20",
  boxShadow: 5,
};

export const commonStylesBorrowList = {
  display: "flex",
  alignItems: "center",
  bgcolor: 'background.paper',
  p: 2,
  width: '100%',
  minHeight: 455,
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#789aa1",
  borderRightColor: "#789aa1",
  boxShadow: 5,
};

export const commonStylesBorrowListMobile = {
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '80%',
  height: 'auto',
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#789aa1",
  borderRightColor: "#789aa1",
  boxShadow: 5,
};

export const commonStylesIceList = {
  display: "flex",
  alignItems: "center",
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '100%',
  minHeight: "500px",
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#0b1a4a",
  borderRightColor: "#0b1a4a",
  boxShadow: 5,
};

export const commonStylesSummaryList = {
  display: "flex",
  alignItems: "center",
  bgcolor: 'background.paper',
  m: 1,
  p: 2,
  width: '100%',
  minHeight: "500px",
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#4527a0",
  borderRightColor: "#4527a0",
  boxShadow: 5,
};

export const commonStylesSummaryTapOut = {
  bgcolor: 'background.paper',
  m: 1,
  // p: 0.5,
  width: 'auto',
  height: 'auto',
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#4caf50",
  borderRightColor: "#4caf50",
  boxShadow: 5,
};

export const commonStylesSummaryTapIn = {
  bgcolor: 'background.paper',
  m: 1,
  // p: 0.5,
  width: 'auto',
  height: 'auto',
  borderRadius: '16px',
  borderLeft: 5,
  borderRight: 5,
  borderLeftColor: "#f44336",
  borderRightColor: "#f44336",
  boxShadow: 5,
};

export const commonStylesIceRecord = {
  bgcolor: '#EB9E23',
  m: 3,
  width: "106ch",
  height: 60,
  display: "flex",
  alignItems: "center",
};

export const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledSubTableRow = styled(TableRow)(({ theme }) => ({

  border: 0,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledSummaryTbl = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#efefef",
}));

export const StyledSummaryCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    fontWeight: "bold",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: "bold",
    padding: "0px",
  },
}));

export const StyledSummaryCellBottom = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: "bold",
  },
}));

export const StyledCellSecond = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.body}`]: {
    border: 0,
  },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.body}`]: {
    // border: 0,
    border: "dotted 1px",
    fontSize: 14,
  },
}));

export const StyledCellHead = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    fontSize: 14,
    padding: "4px",
  },
}));