import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import AgentList from '../components/agent/AgentList';

function Agent() {
    return (
        <>
            <Grid >
                <PageHeader title="Agent" path="Agent/AgentRecord" />
                <AgentList/>
            </Grid>
        </>
    )
}

export default Agent;