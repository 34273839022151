import React, { useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';

//theme
import { SaveButton, TypographyText } from '../../Theme';

//components
import PageHeader from '../common/PageHeader';

//service
import { POST, CREATEAGENT } from '../../service/service';

//alert
import Swal from 'sweetalert2'

//icon
import { SaveIcon } from '../../untils/icons';

//router
import { useLocation } from "react-router-dom";

function AgentRecord() {
    const [name, setName] = useState('');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const location = useLocation(); 

    const createMerchant = async () => {
        try {
            if (name === '') {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเช็ค Name`,
                    confirmButtonText: 'ตกลง',
                });
                return;
            }
            let res = await POST(CREATEAGENT, { name });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Agent");
                    }
                });

            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            });
        }
    };

    const showComponent = location.pathname === "/Agent/AgentRecord" || !isMobile;

    return showComponent ? (
        <>
            <Grid>
                <PageHeader title="เพิ่ม Agent" buttonBack={true} />
                <Box display="flex" justifyContent="center" p={5}>
                    <Paper sx={{ width: '100%', p: 3 }} elevation={0}>
                        <TypographyText sx={{ p: 1, fontSize: 24, fontWeight: "bold" }}> เพิ่มสมาชิกตัวแทน</TypographyText>
                        <Grid container direction="row" spacing={4}>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic" label="ชื่อร้าน" value={name} onChange={(e) => setName(e.currentTarget.value)} variant="outlined" placeholder='ชื่อร้าน' required fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 1, mb: 3, height: '6ch' }} display="flex" justifyContent="center">
                                    <SaveButton fullWidth variant="outlined" onClick={() => createMerchant()} startIcon={<SaveIcon />}>บันทึก</SaveButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Grid>
        </>
    ) : null;
}

export default AgentRecord;