import React from 'react'
import {
    Box,
    Grid,
    Paper,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import UserTable from '../components/user/UserTable';
import { SaveButton } from '../Theme';

function User() {
    return (
        <>
            <Grid >
                <PageHeader title="ข้อมูลพนักงาน"
                    TextButton={
                        <SaveButton
                            variant="contained"
                            sx={{
                                display: { xs: "unset", sm: "none", md: "none" },
                                width: { xs: "100%" },
                                fontSize: { xs: "0.65rem", sm: "1rem", md: "1.125rem" },
                            }}
                        >
                            + เพิ่มสมาชิก
                        </SaveButton>
                    }
                    path="User/UserRecord"
                />
                <UserTable />
            </Grid>
        </>
    )
}

export default User;