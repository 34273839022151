import { Button, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material'
import React from 'react'
import { thousandsSeparator } from '../../untils/shortcut'
import moment from 'moment'

function TableMobile({ transactions, page, rowsPerPage, userTimeZone, handleReject, handleApprove, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {transactions.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    align="center"
                                >
                                    No records to display
                                </TableCell>
                            </TableRow>
                        ) : (
                            transactions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((transaction) => (
                                    <TableRow key={transaction.id}>
                                        <div className="transaction-card">
                                            <p><strong>Time:</strong> {moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</p>
                                            <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                            <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                            <p><strong>Merchant Name:</strong> {transaction.merchant.name}</p>
                                            <p><strong>Type:</strong> {transaction.Type}</p>
                                            <p><strong>Amount:</strong> {thousandsSeparator(transaction.amount)}</p>
                                            <p style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <span style={{ display: 'inline-block', padding: '5px', fontWeight: 'bold' }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            width: "100%",
                                                            borderRadius: '20px',
                                                            backgroundColor: "rgba(79, 229, 94, 1)",
                                                            color: "white",
                                                            '&:hover': {
                                                                backgroundColor: "rgba(76, 129, 88, 1)",
                                                            },
                                                        }}
                                                        onClick={() => handleApprove(transaction.id)}
                                                    >
                                                        Approve
                                                    </Button>
                                                </span>
                                                <span style={{ display: 'inline-block', padding: '5px', fontWeight: 'bold' }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            width: "100%",
                                                            borderRadius: '20px',
                                                            backgroundColor: "rgba(220, 53, 69, 1)",
                                                            color: "white",
                                                            '&:hover': {
                                                                backgroundColor: "rgba(148, 68, 76, 1)",
                                                            },
                                                        }}
                                                        onClick={() => handleReject(transaction.id)}
                                                    >
                                                        Reject
                                                    </Button>
                                                </span>
                                            </p>
                                        </div>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table >
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Rows per page"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer >
        </>
    )
}

export default TableMobile