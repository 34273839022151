import * as XLSX from "xlsx";
import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Grid,
    Paper,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    useMediaQuery,
    TableRow,
    TableContainer,
    Table,
    TableBody,
    TablePagination,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';


//calendar
import moment from "moment-timezone";
import { ImportExport } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import {
    SearchButton,
    TypographyText,
} from '../../Theme.jsx';

//service
import { GET, POST, TRANSACTION, MERCHANT } from '../../service/service.js';

//untils
import { thousandsSeparator } from '../../untils/shortcut.js'

//icons
import { SearchTextIcon } from '../../untils/icons.js'
import dayjs from "dayjs";

const TransactionList = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [iceTransectionData, setIceTransectionData] = useState([]);

    const [startDate, setStartDate] = useState(moment().startOf('month').tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
    const [type, setType] = useState('0');
    const [merchantList, setMerchantList] = useState([])
    const [merchant, setMerchant] = useState('0')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [depositFee, setDepositFee] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const isMobile = useMediaQuery("(max-width:599px)");

    const columns = useMemo(
        () => [
            {
                accessorKey: 'transactions_date_created',
                header: 'Time',
                size: 200,
                accessorFn: (e) => moment(e.date_created).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss'),
            },
            {
                accessorKey: 'client_transaction_id',
                header: 'Client Transaction Id',
                size: 50,
            },
            {
                accessorKey: 'system_transaction_id',
                header: 'System Transaction Id',
                size: 50,
            },
            {
                accessorKey: 'merchants_name',
                header: 'Merchant Name',
                size: 50,
            },
            {
                accessorKey: 'tx_type',
                header: 'Type',
                size: 50,
                Cell: row => <div
                    style={{
                        color: row.renderedCellValue === "deposit" ? 'rgba(59, 138, 77, 1)' : 'rgba(214, 77, 81, 1)',
                        backgroundColor: row.renderedCellValue === 'deposit' ? 'rgba(176, 251, 183, 0.25)' : 'rgba(245, 163, 163, 0.25)',
                        borderRadius: '15px',
                        padding: '5px 15px',
                        textAlign: 'center',
                        display: 'inline-block',
                        fontWeight: 'bold',
                    }}
                >
                    {row.renderedCellValue}
                </div>
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.amount),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
            },
            {
                accessorKey: 'fee_amount',
                header: 'Fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => thousandsSeparator(e.fee_amount),
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
            },
            {
                header: 'Agent Fee',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const fee =
                        e.tx_type === "deposit"
                            ? e.agent_fee_in
                                ? e.agent_fee_in * e.amount / 100
                                : 0
                            : e.agent_fee_out
                                ? e.agent_fee_out * e.amount / 100
                                : 0;
                    return thousandsSeparator(fee);
                },

                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
            },
        ],
        [],
    );

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    const exportTransaction = () => {
        if (!iceTransectionData || iceTransectionData.length === 0) {
            alert("No data available to export");
            return;
        }

        const data = iceTransectionData.map(e => {
            return {
                "Merchants Name": e.merchants_name,
                "ClientID": e.client_transaction_id,
                "SystemID": e.system_transaction_id,
                "Amount": thousandsSeparator(e.amount),
                "Amount With Fee": thousandsSeparator(e.amount_with_fee),
                "Sum Fee": thousandsSeparator(e.fee_amount),
                "Total Fee %": thousandsSeparator(e.total_fee),
                "Agent Fee":
                    e.tx_type === "deposit"
                        ? thousandsSeparator(e.agent_fee_in)
                            ? thousandsSeparator((e.agent_fee_in * e.amount) / 100)
                            : "0.00"
                        : thousandsSeparator(e.agent_fee_out)
                            ? thousandsSeparator((e.agent_fee_out * e.amount) / 100)
                            : "0.00",
                "Type": e.tx_type,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(data);

        const columnWidths = {};
        for (let i = 0; i < data.length; i++) {
            Object.keys(data[i]).forEach((key, colIndex) => {
                const cell = worksheet[XLSX.utils.encode_cell({ r: i + 1, c: colIndex })];
                if (cell && cell.v) {
                    const cellValue = cell.v.toString();
                    const currentMaxLength = columnWidths[key] || 0;
                    columnWidths[key] = Math.max(currentMaxLength, cellValue.length);
                }
            });
        }

        worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
            wpx: columnWidths[key] * 10,
        }));

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = range.s.c; col <= range.e.c; col++) {
            const header = XLSX.utils.encode_col(col) + "1";
            const headerValue = worksheet[header]?.v;

            if (
                ["amount", "amount_with_fee", "fee_amount", "total_fee", "agent_fee_in", "agent_fee_out"].includes(
                    headerValue
                )
            ) {
                for (let row = 2; row <= range.e.r + 1; row++) {
                    const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
                    if (worksheet[cell]) {
                        worksheet[cell].t = "n";
                        worksheet[cell].z = "#,##0.00";
                    }
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Deposits");

        const fileName = `Report TransactionList ${localStorage.getItem("name")} ${(startDate).format(
            "DD-MMM-YYYY")} - ${(endDate).format("DD-MMM-YYYY")}.xlsx`;

        XLSX.writeFile(workbook, fileName);
    };

    const searchTransaction = async () => {
        try {
            let res = await POST(TRANSACTION, {
                date_start: dayjs(startDate),
                date_end: dayjs(endDate),
                type: type,
                merchant_id: merchant.toString()
            });
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    new Date(b.date_created) - new Date(a.date_created)
                );

                setIceTransectionData(sortedData);

                let sumDeposit = 0;
                let sumWithdraw = 0;
                let sumDepositFee = 0;
                let sumWithdrawFee = 0;

                res.result.forEach(e => {
                    if (e.tx_type === 'deposit') {
                        sumDeposit += Number(e.amount);
                        sumDepositFee += Number(e.fee_amount);
                    } else {
                        sumWithdraw += Number(e.amount);
                        sumWithdrawFee += Number(e.fee_amount);
                    }
                });

                setDepositAmount(sumDeposit);
                setWithdrawAmount(sumWithdraw);
                setWithdrawFee(sumWithdrawFee);
                setDepositFee(sumDepositFee);

            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            console.error("Error occurred during transaction processing:", error);
            alert("An error occurred. Please try again.");
        }
    }

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }

            }
        } catch (error) {
            alert("error", error);
        }
    };

    useEffect(() => {
        searchTransaction();
        getMerchant();
        // getUser();
    }, []);

    const InfoBox = ({ imgSrc, title, amount }) => (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 1,
                bgcolor: '#FBE2F1',
                boxShadow: 2,
            }}
        >
            <Box
                sx={{
                    width: 100,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src={imgSrc} alt={title} style={{ width: "100%", height: "100%", objectFit: 'contain', }} />
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: { xs: '14px', sm: '16px', md: '18px' },
                        textAlign: 'right',
                        mb: 1.5,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: '18px', sm: '20px', md: '24px' },
                        fontWeight: 'bold',
                        textAlign: 'right',
                        color: '#333',
                    }}
                >
                    {amount} ฿
                </Typography>
            </Box>
        </Box>
    );

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '90%' }} elevation={0}>
                        <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
                            <Box sx={{ border: { xs: 'none', sm: 1 }, borderRadius: { xs: 'none', sm: 1 }, backgroundColor: { xs: 'none', sm: 'rgba(251, 226, 241, 1)' }, borderColor: { xs: 'none', sm: 'rgba(0, 0, 0, 0.12)' }, boxShadow: { xs: 'none', sm: '0px 4px 10px rgba(0, 0, 0, 0.25)' } }}>
                                <TypographyText sx={{ mb: { xs: 2, sm: 0 }, p: { xs: 0, sm: 2 }, fontSize: { xs: '20px', sm: '18px', md: '20px' }, fontWeight: 'bold' }}>
                                    ค้นหา
                                </TypographyText>
                                <Grid item xs={12} sx={{ p: { xs: 0, sm: 3 } }}>
                                    <Grid container direction="row" spacing={2}>
                                        {/* Type Selector */}
                                        <Grid item xs={12} sm={2.5} md={2.5}>
                                            <InputLabel sx={{ textAlign: "start" }} id="demo-simple-select-label">ประเภท</InputLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                    <MenuItem value={'1'}>Deposit</MenuItem>
                                                    <MenuItem value={'2'}>Withdraw</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Merchant Selector */}
                                        <Grid item xs={12} sm={2.5} md={2.5}>
                                            <InputLabel sx={{ textAlign: "start" }} id="demo-simple-select-label">ร้านค้า</InputLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={merchant}
                                                    onChange={(e) => setMerchant(e.target.value)}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <MenuItem value={'0'}>ทั้งหมด</MenuItem>
                                                    {merchantList.map((e) => (
                                                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* Start Date Picker */}
                                        <Grid item xs={12} sm={2} md={2}>
                                            <InputLabel sx={{ textAlign: "start" }} id="demo-simple-select-label">วันที่เริ่มต้น</InputLabel>
                                            <DesktopDatePicker
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ backgroundColor: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>

                                        {/* End Date Picker */}
                                        <Grid item xs={12} sm={2} md={2}>
                                            <InputLabel sx={{ textAlign: "start" }} id="demo-simple-select-label">วันที่สิ้นสุด</InputLabel>
                                            <DesktopDatePicker
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                                minDate={startDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ backgroundColor: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>

                                        {/* Search and Export Buttons */}
                                        <Grid item xs={12} sm={2.5} md={1.5} sx={{ mt: { sx: 2, sm: 4 } }}>
                                            <SearchButton width="auto" variant="outlined" onClick={searchTransaction} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                        </Grid>
                                        <Grid item xs={12} sm={2.5} md={1.5} sx={{ mt: { sx: 2, sm: 4 } }}>
                                            <SearchButton width="auto" variant="outlined" onClick={exportTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>


                        {/* Info Box */}
                        <Grid container spacing={2}
                            sx={{
                                mb: { xs: 5, sm: 'unset' },
                                p: 2,
                                display: 'flex',
                                flexWrap: 'nowrap',
                                overflowX: { xs: 'auto', sm: 'auto' },
                            }}
                        >
                            <Grid item xs={12} sm={6} md={3}>
                                <InfoBox imgSrc="../img/image0.png" title="ยอดฝาก" amount={thousandsSeparator(depositAmount)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InfoBox imgSrc="../img/image1.png" title="ยอดถอน" amount={thousandsSeparator(withdrawAmount)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InfoBox imgSrc="../img/image2.png" title="ค่าธรรมเนียมฝาก" amount={thousandsSeparator(depositFee)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InfoBox imgSrc="../img/image3.png" title="ค่าธรรมเนียมถอน" amount={thousandsSeparator(withdrawFee)} />
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{
                                color: "rgba(145, 143, 143, 1)",
                                display: { xs: "flex", sm: "none" },
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "15px"
                            }}
                        >
                            <span>ผลค้นหา</span>
                            <span>{iceTransectionData.length} รายการ</span>
                        </Grid>

                        {/* Material Table */}
                        {isMobile ? (
                            iceTransectionData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((column, index) => {
                                    const agentFee =
                                        column.tx_type === "deposit"
                                            ? column.agent_fee_in
                                                ? (column.agent_fee_in * column.amount) / 100
                                                : 0
                                            : column.agent_fee_out
                                                ? (column.agent_fee_out * column.amount) / 100
                                                : 0;

                                    return (
                                        <TableContainer key={index}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <div className="transaction-card">
                                                            <p><strong>Time:</strong> {moment(column.date_created).tz(userTimeZone).format('DD/MMM/YYYY HH:mm:ss')}</p>
                                                            <p><strong>Client Transaction ID:</strong> {column.client_transaction_id}</p>
                                                            <p><strong>System Transaction ID:</strong> {column.system_transaction_id}</p>
                                                            <p><strong>Merchant Name:</strong> {column.merchants_name}</p>
                                                            <p><strong>Type: </strong><span style={{ color: column.tx_type === "deposit" ? "green" : "red" }}>{column.tx_type}</span></p>
                                                            <p><strong>Amount:</strong> {thousandsSeparator(column.amount)}</p>
                                                            <p><strong>Fee:</strong> {thousandsSeparator(column.fee_amount)}</p>
                                                            <p><strong>Agent Fee:</strong> {thousandsSeparator(agentFee)}</p>
                                                        </div>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    );
                                })
                        ) : (
                            <MaterialReactTable
                                columns={columns}
                                data={iceTransectionData}
                                enableColumnOrdering
                                enableEditing={false}
                                enableRowNumbers
                                rowNumberMode="original"
                                muiTablePaperProps={{
                                    sx: { border: "1px solid black" },
                                }}
                            />
                        )}
                        <TablePagination sx={{ display: { xs: 'unset', sm: 'none', md: 'none' } }}
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            labelRowsPerPage="Rows per page"
                            count={iceTransectionData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Grid>
        </LocalizationProvider >
    )
}

export default TransactionList;