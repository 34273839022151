import React, { useState, useEffect, useMemo } from 'react'
import {
    Box,
    Paper,
    IconButton,
    Button,
    Grid,
    useMediaQuery,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TablePagination
} from "@mui/material";

import { MaterialReactTable } from 'material-react-table';

import { useNavigate } from "react-router-dom";

//service
import { GET, POST, DELETECAR, SEARCHCAR, MERCHANT } from '../../service/service';
import ModeIcon from '@mui/icons-material/Mode';
import MerchantRecord from './MerchantRecord';

//alert
import Swal from 'sweetalert2'
import { thousandsSeparator } from '../../untils/shortcut';

const CarTable = () => {

    const [data, setData] = useState([]);
    const [text, setText] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };


    const navigate = useNavigate();

    const handleRoute = (path) => {
        navigate(path);
    };

    const getMerchant = async () => {
        try {
            let res = await GET(MERCHANT);
            if (res.success) {
                setData(res.result);
            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    alert(res.message);
                }
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteCar = async (car_id) => {
        try {
            let res = await POST(DELETECAR, { status: false, car_id });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.result.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteAlert = (car_id) => {
        Swal.fire({
            title: 'คุณต้องการลบหรือไม่?',
            text: "ถ้าลบแล้วคุณไม่สามารถกู้คืนรายชื่อพนักงานขับรถได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ลบ',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ไม่ลบ',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCar(car_id);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                    // 'Cancelled',
                    // 'Your imaginary file is safe :)',
                    // 'error'
                })
            }
        })
    }

    const searchCar = async () => {
        try {
            let res = await POST(SEARCHCAR, { text_search: text });
            if (res.success) {
                setData(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("error", error);
        }
    };
    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'car_part_id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     disabled: true,
            //     size: 80,
            // },
            {
                accessorKey: 'name',
                header: 'Merchant Name',
                size: 50,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'provider',
                header: 'Provider',
                size: 50,
                accessorFn: (e) => (e.credentials[0].provider),
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Fee In',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const totalFeeIn = e.setting[0].providerFeeIn + e.setting[0].agentFeeIn + e.setting[0].systemFeeIn;
                    return thousandsSeparator(totalFeeIn)
                },
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Fee Out',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => {
                    const totalFeeOut = e.setting[0].providerFeeOut + e.setting[0].agentFeeOut + e.setting[0].systemFeeOut;
                    return thousandsSeparator(totalFeeOut);
                },
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                header: 'Agent',
                size: 100,
                accessorFn: (e) => e.setting[0].agent?.name ?? e.setting[0].agent,
            },
            {
                accessorKey: 'balance',
                header: 'Wallet',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => e.balances[0] ? (thousandsSeparator(e.balances[0]?.balance)) : 0,
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },
            {
                accessorKey: 'balance withdraw',
                header: 'balance withdraw',
                inputType: 'number',
                size: 50,
                accessorFn: (e) => e.balances[0] ? (thousandsSeparator(e.balances[0].balanceWithdraw)) : 0,
                Cell: row => <div style={{ textAlign: "right" }}>{row.renderedCellValue}</div>
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
            },

        ],
        [],
    );

    const handleAddMerchant = () => {
        navigate('/Merchant/merchantRecord');
    };

    useEffect(() => {
        getMerchant();
    }, []);

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {/* ค้นหา */}
            <Box display="flex" justifyContent="center">
                <Paper sx={{ width: '90%', py: 3 }} elevation={0}>
                    <Grid item xs={12} sx={{ justifyContent: "flex-end", textAlign: "right", display: "flex" }}>
                        <Button variant="contained" onClick={handleAddMerchant} sx={{ display: { xs: "none", sm: "unset", md: "unset" }, width: 200, mb: 2, backgroundColor: "rgba(202, 109, 229, 1)", "&:hover": { bgcolor: "rgba(129, 42, 154, 1)" } }} > + เพิ่มร้านค้า</Button>
                    </Grid>
                    {/* <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItems="center">
                        ค้นหา
                    </Typography>
                    <Grid item xs={12} sx={{ py: 2 }}>
                        <TextField
                            id="outlined-basic"
                            label="ชื่อร้านค้า"
                            variant="outlined"
                            placeholder='ร้านค้า'

                            required
                            size='medium'
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.currentTarget.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    searchCar("");
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchTextIcon onClick={searchCar} />
                                        </IconButton >
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid> */}

                    <Grid
                        sx={{
                            color: "rgba(145, 143, 143, 1)",
                            display: { xs: "flex", sm: "none" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px"
                        }}
                    >
                        <span>ผลค้นหา</span>
                        <span>{data.length} รายการ</span>
                    </Grid>

                    {isMobile ? (
                        data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((column, index) => (
                                <TableContainer key={index}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <div className="transaction-card">
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <p>
                                                            <strong>Merchant Name:</strong> {column.name}
                                                        </p>

                                                        <IconButton
                                                            sx={{
                                                                width: "60px",
                                                                borderRadius: "20px",
                                                                color: "white",
                                                                backgroundColor: "rgba(143, 137, 226, 1)",
                                                                "&:hover": { backgroundColor: "rgba(202, 109, 229, 1)", color: "white" }
                                                            }}
                                                            onClick={() => handleRoute(`/Merchant/edit/${column.id}`)}
                                                        >
                                                            <ModeIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <p><strong>Provider:</strong> {column.credentials[0]?.provider ?? ""}</p>
                                                    <p><strong>Fee In:</strong> {thousandsSeparator((column.setting[0]?.providerFeeIn ?? 0) + (column.setting[0]?.agentFeeIn ?? 0) + (column.setting[0]?.systemFeeIn ?? 0))}</p>
                                                    <p><strong>Fee Out:</strong> {thousandsSeparator((column.setting[0]?.providerFeeOut ?? 0) + (column.setting[0]?.agentFeeOut ?? 0) + (column.setting[0]?.systemFeeOut ?? 0))}</p>
                                                    <p><strong>Agent: </strong>{column.setting[0]?.agent?.name ?? ""}</p>
                                                    <p><strong>Wallet:</strong> {thousandsSeparator(column.balances[0]?.balance ?? 0)}</p>
                                                    <p><strong>Balance Withdraw:</strong> {thousandsSeparator(column.balances[0]?.balanceWithdraw ?? 0)}</p>
                                                </div>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))
                    ) : (
                        <MaterialReactTable
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 10,
                                },
                            }}
                            columns={columns}
                            data={data}
                            enableColumnOrdering
                            enableEditing={false}
                            enableRowNumbers
                            rowNumberMode="original"
                            enableRowActions
                            renderRowActions={({ row }) => (
                                <Box textAlign="center">
                                    <IconButton sx={{ color: "white", backgroundColor: "rgba(143, 137, 226, 1)", "&:hover": { backgroundColor: "rgba(202, 109, 229, 1)", color: "white" } }} onClick={() => handleRoute(`/Merchant/edit/${row.original.id}`)}>
                                        <ModeIcon />
                                    </IconButton>
                                </Box>
                            )}
                            muiTablePaperProps={{
                                sx: {
                                    border: '1px solid black',
                                },
                            }}
                        />
                    )}
                    <TablePagination sx={{ display: { xs: 'unset', sm: 'none', md: 'none' } }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box >

            {/* List */}



        </>
    )
}

export default CarTable;