import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    useMediaQuery,
    Box,
} from "@mui/material";
import { ImportExport } from '@mui/icons-material';

//exportTransaction
import * as XLSX from "xlsx";

//calendar
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import { SearchButton, TypographyText } from '../../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../../untils/icons.js'

//services
import { POST, GET, TRANSACTIONSWITHDRAW, MERCHANT, REFUND } from "../../../service/service.js"
import ReportWithdrawDesktop from "./ReportWithdrawDesktop.js";
import { thousandsSeparator } from "../../../untils/shortcut.js";
import ReportWithdrawMobile from "./ReportWithdrawMobile.js";
import moment from "moment-timezone";

function ReportWithdraw() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isMobile = useMediaQuery('(max-width:600px)');

    const [text, setText] = useState('');
    const [exportTransaction, setExportTransaction] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [broker, setBroker] = useState("All");
    const [brokerList, setBrokerList] = useState([]);
    const [status, setStatus] = useState("All")
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text })
        getBroker();
    }, []);

    const getTransactionMerchant = async ({ merchant_name: broker, startDate, endDate, search: text }) => {
        try {
            const res = await POST(TRANSACTIONSWITHDRAW, {
                search: text,
                merchant_name: broker,
                status: status,
                startDate: startDate,
                endDate: endDate
            })
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    (b.id) - (a.id)
                );
                setTransactions(sortedData)
                setExportTransaction(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getBroker = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setBrokerList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching brokers");
        }
    };

    const handleSearch = () => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text, })
        setPage(0);
    };

    const updateTransactionStatus = (systemTransactionId) => {
        setTransactions(prevTransactions =>
            prevTransactions.map(transaction =>
                transaction.systemTransactionId === systemTransactionId
                    ? { ...transaction, refund: 1 }
                    : transaction
            )
        );
    };

    const handleRefund = async (systemTransactionId) => {
        const ref_no = String(systemTransactionId);
        const isConfirmed = window.confirm("Are you sure you want to refund this transaction?");
        if (isConfirmed) {
            try {
                const response = await POST(REFUND, { ref_no });
                if (response.success) {
                    alert("Refund successful!");
                    // Update the refund status of this transaction in the frontend state
                    updateTransactionStatus(systemTransactionId);
                } else {
                    alert(`Refund failed: ${response.message}`);
                }
            } catch (error) {
                alert("Error processing the refund");
            }
        }
    };

    const getStatusBackground = (status) => {
        switch (status) {
            case 'pending':
                return 'rgba(246,247,162,0.25)';
            case 'successful':
                return 'rgba(176,251,183,0.25)';
            case 'failed':
                return 'rgba(245,163,163,0.25)';
            default:
                return 'black';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'rgba(212,183,0,1)';
            case 'successful':
                return 'rgba(59,138,77,1)';
            case 'failed':
                return 'rgba(214,77,81,1)';
            default:
                return 'black';
        }
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    const exTransaction = () => {
        if (!exportTransaction || exportTransaction.length === 0) {
            alert("No data available to export");
            return;
        }

        let data = exportTransaction.map(e => {
            return {
                "TransactionsID": e.id,
                "ClientTransactionID": e.clientTransactionId,
                "MerchantName": e.merchant.name,
                "Balance Before": thousandsSeparator(e.balanceBefore),
                "Balance After": thousandsSeparator(e.balanceAfter),
                "Amount": thousandsSeparator(e.amount),
                "Amount With Fee": thousandsSeparator(e.amountWithFee),
                "Error": e.error,
                "Status": e.status,
                "Ref1": e.ref1,
                "BankName": e.bankName,
                "BookBankNumber": e.bookBankNumber,
                "BookBankName": e.bookBankName,
                "BookBankBranch": e.bookBankBranch,
                "Date Created": moment(e.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss"),
                "Date Updated": moment(e.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss"),
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(data);

        const columnWidths = {};
        for (let i = 0; i < data.length; i++) {
            Object.keys(data[i]).forEach((key, colIndex) => {
                const cell = worksheet[XLSX.utils.encode_cell({ r: i + 1, c: colIndex })];
                if (cell && cell.v) {
                    const cellValue = cell.v.toString();
                    const currentMaxLength = columnWidths[key] || 0;
                    columnWidths[key] = Math.max(currentMaxLength, cellValue.length);
                }
            });
        }

        worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
            wpx: columnWidths[key] * 10,
        }));

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = range.s.c; col <= range.e.c; col++) {
            const header = XLSX.utils.encode_col(col) + "1";
            const headerValue = worksheet[header]?.v;

            if (
                ["balanceBefore", "balanceAfter", "amount", "amountWithFee"].includes(
                    headerValue
                )
            ) {
                for (let row = 2; row <= range.e.r + 1; row++) {
                    const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
                    if (worksheet[cell]) {
                        worksheet[cell].t = "n";
                        worksheet[cell].z = "#,##0.00";
                    }
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "WithDraw");

        const fileName = `${startDate.format("DD/MMM/YYYY")}_${endDate.format("DD/MMM/YYYY")}_Withdraw_transactions_merchant_${broker}_status_${status}.xlsx`

        XLSX.writeFile(workbook, fileName);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '90%' }} elevation={0}>
                        <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
                            <Box sx={{ border: { xs: 'none', sm: 1 }, borderRadius: { xs: 'none', sm: 1 }, backgroundColor: { xs: 'none', sm: 'rgba(251, 226, 241, 1)' }, borderColor: { xs: 'none', sm: 'rgba(0, 0, 0, 0.12)' }, boxShadow: { xs: 'none', sm: '0px 4px 10px rgba(0, 0, 0, 0.25)' } }}>
                                <TypographyText sx={{ mb: { xs: 2, sm: 0 }, p: { xs: 0, sm: 2 }, fontSize: { xs: '20px', sm: '18px', md: '20px' }, fontWeight: 'bold' }}>
                                    ค้นหา
                                </TypographyText>
                                <Grid item xs={12} sx={{ p: { xs: 0, sm: 2 } }}>
                                    <Grid container direction="row" marginBottom={2} spacing={2}>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={text}
                                                    onChange={(e) => setText(e.target.value)}
                                                    variant="outlined"
                                                    placeholder='ค้นหา'
                                                    required
                                                    fullWidth
                                                    sx={{ background: 'white' }}
                                                >
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>ร้านค้า</InputLabel>
                                                <Select
                                                    value={broker}
                                                    onChange={(e) => setBroker(e.target.value)}
                                                    label='Broker'
                                                    sx={{ background: 'white' }}
                                                >
                                                    <MenuItem value={"All"}>{"All"}</MenuItem>
                                                    {brokerList.map((item) => (
                                                        <MenuItem key={item.name} value={item.name}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>Status</InputLabel>
                                                <Select
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    label='Status'
                                                    sx={{ background: 'white' }}
                                                >
                                                    <MenuItem value={'All'}>ทั้งหมด</MenuItem>
                                                    <MenuItem value={'pending'}>Pending</MenuItem>
                                                    <MenuItem value={'successful'}>Successful</MenuItem>
                                                    <MenuItem value={'failed'}>Failed</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <DesktopDatePicker
                                                label="วันที่เริ่มต้น"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <DesktopDatePicker
                                                label="วันที่สิ้นสุด"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                                minDate={startDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1} sx={{ mt: { sx: 2, sm: 1 } }}>
                                            <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1} sx={{ mt: { sx: 2, sm: 1 } }}>
                                            <SearchButton width="auto" variant="outlined" onClick={exTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Grid
                                sx={{
                                    color: "rgba(145, 143, 143, 1)",
                                    display: { xs: "flex", sm: "none" },
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "15px"
                                }}
                            >
                                <span>ผลค้นหา</span>
                                <span>{transactions.length} รายการ</span>
                            </Grid>

                            {isMobile ? (
                                <ReportWithdrawMobile
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleRefund={handleRefund}
                                    getStatusColor={getStatusColor}
                                    getStatusBackground={getStatusBackground}
                                />
                            ) : (
                                <ReportWithdrawDesktop
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleRefund={handleRefund}
                                    getStatusColor={getStatusColor}
                                    getStatusBackground={getStatusBackground}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Box>
            </Grid >
        </LocalizationProvider>
    );
};

export default ReportWithdraw;