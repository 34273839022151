import React, { useState } from 'react'
import { SaveButton, TypographyText } from '../../Theme'
import { Box, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material'
import { SaveIcon } from '../../untils/icons';
import Swal from 'sweetalert2';
import { CREATEAGENT, POST } from '../../service/service';
import { useLocation } from 'react-router-dom';

function AgnetRecordMoblie() {
    const [name, setName] = useState('');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const location = useLocation();

    const createMerchant = async () => {
        try {
            if (name === '') {
                Swal.fire({
                    icon: 'error',
                    text: `กรุณาเช็ค Name`,
                    confirmButtonText: 'ตกลง',
                });
                return;
            }
            let res = await POST(CREATEAGENT, { name });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    text: `${res.message}`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Agent");
                    }
                });

            } else {
                if (res.statusCode === 401) {
                    localStorage.removeItem('token_Login');
                    localStorage.removeItem('username');
                    window.location.replace("/");
                } else {
                    Swal.fire({
                        icon: 'warning',
                        text: `${res.message}`,
                        confirmButtonText: 'ตกลง',
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            });
        }
    };

    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'unset', md: 'unset' } }} display="flex" justifyContent="flex-start">
                <Paper sx={{ width: '100%' }} elevation={0}>
                    <Box sx={{ p: 2, my: 2, border: 1, backgroundColor: 'rgba(251, 226, 241, 1)', borderColor: 'rgba(0, 0, 0, 0.12)', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)' }}>
                        <TypographyText sx={{ p: 1, fontSize: 24, fontWeight: "bold" }}>
                            เพิ่มสมาชิกตัวแทน
                        </TypographyText>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={8} md={5}>
                                <TextField
                                    sx={{ backgroundColor: "white" }}
                                    id="outlined-basic"
                                    label="ชื่อร้าน"
                                    value={name}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                    variant="outlined"
                                    placeholder="ชื่อร้าน"
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <SaveButton
                                    variant="outlined"
                                    onClick={createMerchant}
                                    startIcon={<SaveIcon />}
                                    sx={{ height: "100%", whiteSpace: "nowrap" }}
                                >
                                    บันทึก
                                </SaveButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </>
    )
}

export default AgnetRecordMoblie