import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'

function TableDestop({ transactions, page, rowsPerPage, userTimeZone, handleRowClick, handleChangePage, handleChangeRowsPerPage }) {
    return (
        <>
            <TableContainer component={Paper} sx={{ marginTop: 5, border: '1px solid black' }}>
                <Table sx={{ minWidth: 900 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Broker</TableCell>
                            <TableCell>Client Transaction ID</TableCell>
                            <TableCell>System Transaction ID</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    No records to display
                                </TableCell>
                            </TableRow>
                        ) : (
                            transactions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((transaction, index) => (
                                    <TableRow key={transaction.id} hover onClick={() => handleRowClick(transaction)}>
                                        <TableCell>{transaction.merchantName}</TableCell>
                                        <TableCell>{transaction.clientTransactionId}</TableCell>
                                        <TableCell>{transaction.systemTransactionId}</TableCell>
                                        <TableCell>{moment(transaction.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")}</TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    labelRowsPerPage="Rows per page"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}

export default TableDestop