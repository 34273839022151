import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, IconButton, InputAdornment, Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";

// Service
import { POST_AUTH, login } from "../service/service.js";

// SweetAlert2
import Swal from "sweetalert2";

function Login() {
  const [values, setValues] = useState({
    username: "",
    password: "",
    showPassword: false,
    loading: false,
  });

  useEffect(() => {
    const token = window.localStorage.getItem("token_Login");
    if (token) {
      window.location.replace("/");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setValues((prevValues) => ({
      ...prevValues,
      showPassword: !prevValues.showPassword,
    }));
  };

  const handleLogin = useCallback(async () => {
    setValues((prevValues) => ({ ...prevValues, loading: true }));
    try {
      const { username, password } = values;
      const res = await POST_AUTH(login, { username, password });

      if (res.success) {
        window.localStorage.setItem("token_Login", res.apiToken);
        window.localStorage.setItem("role", "admin");
        window.localStorage.setItem("username", res.name);
        window.localStorage.setItem("user_id", res.id);
        window.location.replace("/");
      } else {
        Swal.fire({
          icon: "warning",
          text: res.message,
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.message || "An error occurred.",
        confirmButtonText: "ตกลง",
      });
    } finally {
      setValues((prevValues) => ({ ...prevValues, loading: false }));
    }
  }, [values]);

  return (
    <Grid className="login">
      <Grid className="login-container test-login-container">
        <Grid className="f1">
          <Grid className="Ellipse-1"></Grid>
          <h2 className="login-title">Admin</h2>
          <img
            src="https://www.happypay.asia/logo.svg"
            alt="Happy Pay Logo"
            className="login-logo"
          />
        </Grid>

        <form
          className="login-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <Grid item xs={12} sm={12} md={12} className="test-login-input test-login-box">
            <TextField
              className="test-input"
              placeholder="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              name="username"
              value={values.username}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="test-input"
              placeholder="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              name="password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Button
            className="login-button f2"
            type="submit"
            variant="contained"
            color="primary"
            disabled={values.loading}
            fullWidth
            style={{ marginTop: "16px" }}
          >
            {values.loading ? "Logging in..." : "Login"}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login;