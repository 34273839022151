import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Typography, OutlinedInput, Select, TextField, Box, Paper, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET, MERCHANT, POST, WITHDRAWBROKER, WITHDRAWLISTBROKER } from '../../service/service';
import { SearchButton, TypographyText } from '../../Theme';
import TableData from './TableData';
import dayjs from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { SearchTextIcon } from '../../untils/icons.js'
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NumericFormat } from 'react-number-format';
import TableDataMobile from './TableDataMobile.js';

function WithdrawsBroker() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isMobile = useMediaQuery('(max-width:600px)');

    const [merchant, setMerchant] = useState();
    const [merchantsearch, setMerchantsearch] = useState(0);
    const [merchantList, setMerchantList] = useState([]);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf("day").tz(userTimeZone));
    const [withdrawlist, setWithdrawlist] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        WithdrawListBroker();
        getMerchant();
    }, []);

    const getMerchant = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Error fetching merchants:', error);
            alert("Failed to fetch merchants.");
        }
    };

    const withdrawBroker = async (broker, amount) => {
        setLoading(true);
        try {
            const res = await POST(WITHDRAWBROKER, {
                merchantid: broker,
                amount: amount,
            });
            if (res.success) {
                WithdrawListBroker();
                setWithdrawAmount('');
                if (amount) {
                    amount = Number(amount);
                }
                alert(`Withdrawal successful: ฿${amount}`);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Withdrawal error:', error);
            alert('Withdrawal failed. Please try again.');
        }
        setLoading(false);
    };

    const WithdrawListBroker = async () => {
        try {
            const res = await POST(WITHDRAWLISTBROKER, {
                date_start: startDate,
                date_end: endDate,
                merchant_id: merchantsearch
            });
            if (res.success) {
                setPage(0);
                const sortedList = res.result.sort((a, b) =>
                    dayjs(b.date_created).valueOf() - dayjs(a.date_created).valueOf()
                );
                setWithdrawlist(sortedList);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching withdrawals: " + error);
        }
    };

    const handleWithdraw = async () => {
        if (!merchant) {
            alert('Please select a Merchant');
            return;
        }
        if (!withdrawAmount) {
            alert('Please enter an Amount');
            return;
        }
        if (withdrawAmount <= 0) {
            alert('Please enter an Amount');
            return;
        }
        if (withdrawAmount > 10000000) {
            alert('Amount must be less than 10,000,000');
            return;
        }
        setLoading(true);
        try {
            await withdrawBroker(merchant, withdrawAmount);
        } finally {
            setLoading(false);
        }
    };


    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    const handleSearch = () => {
        WithdrawListBroker({ startDate, endDate, merchant_name: merchant })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Grid alignItems="center" justifyContent="center">
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Paper sx={{ width: '100%' }} elevation={0}>
                        <Box sx={{
                            border: 1, backgroundColor: {
                                xs: 'transparent',
                                sm: 'rgba(251, 226, 241, 1)',
                            },
                            borderColor: {
                                xs: 'transparent',
                                sm: 'rgba(0, 0, 0, 0.12)',
                            },
                            boxShadow: {
                                xs: 'none',
                                sm: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                            },
                            p: { xs: 'none', sm: 2 },
                            borderRadius: 2,
                            mb: { xs: 'none', sm: 5 },
                        }}>
                            <Grid container spacing={2} marginBottom={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>ร้านค้า</InputLabel>
                                        <Select
                                            value={merchant}
                                            onChange={(e) => setMerchant(e.target.value)}
                                            label="ร้านค้า"
                                            sx={{ background: 'white' }}
                                        >
                                            {merchantList.map((item) => (
                                                <MenuItem key={item.name} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <NumericFormat
                                        value={withdrawAmount}
                                        customInput={TextField}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        decimalScale={2}
                                        allowNegative={false}
                                        fixedDecimalScale
                                        fullWidth
                                        label="Amount"
                                        sx={{ background: 'white' }}
                                        isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return floatValue === undefined || floatValue <= 10000000;
                                        }}
                                        onValueChange={(values) => {
                                            const { floatValue } = values;
                                            setWithdrawAmount(floatValue || "");
                                        }}
                                        InputProps={{
                                            startAdornment: <span style={{ marginRight: '5px' }}>฿</span>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SearchButton
                                        fullWidth
                                        variant="outlined"
                                        onClick={handleWithdraw}
                                        disabled={loading}
                                    >
                                        {loading ? "Processing..." : "Withdraw"}
                                    </SearchButton>
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 2, mb: 2 }}>
                                <TypographyText sx={{ mb: { xs: 2, sm: 0 }, p: { xs: 0, sm: 2 }, fontSize: { xs: '20px', sm: '18px', md: '20px' }, fontWeight: 'bold' }}>
                                    ค้นหา
                                </TypographyText>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>ร้านค้า</InputLabel>
                                            <Select
                                                value={merchantsearch}
                                                onChange={(e) => setMerchantsearch(e.target.value)}
                                                label="ร้านค้า"
                                                sx={{ background: 'white' }}
                                            >
                                                <MenuItem value={0}>All</MenuItem>
                                                {merchantList.map((item) => (
                                                    <MenuItem key={item.name} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid item xs={12} sm={2.5}>
                                            <DesktopDatePicker
                                                label="วันที่เริ่มต้น"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2.5}>
                                            <DesktopDatePicker
                                                label="วันที่สิ้นสุด"
                                                inputFormat="DD/MMM/YYYY HH:mm:ss"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                                minDate={startDate}
                                                maxDate={dayjs()}
                                                renderInput={(params) => <TextField sx={{ background: 'white' }} {...params} fullWidth />}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <Grid item xs={12} sm={3} md={3} sx={{ mt: 1 }}>
                                        <SearchButton fullWidth variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>
                                            ค้นหา
                                        </SearchButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Grid
                            sx={{
                                color: "rgba(145, 143, 143, 1)",
                                display: { xs: "flex", sm: "none" },
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "15px"
                            }}
                        >
                            <span>ผลค้นหา</span>
                            <span>{withdrawlist.length} รายการ</span>
                        </Grid>

                        {isMobile ? (
                            <TableDataMobile
                                withdrawlist={withdrawlist}
                                startDate={startDate}
                                endDate={endDate}
                                broker={merchant}
                                page={page}
                                userTimeZone={userTimeZone}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        ) : (
                            <TableData
                                withdrawlist={withdrawlist}
                                startDate={startDate}
                                endDate={endDate}
                                broker={merchant}
                                page={page}
                                userTimeZone={userTimeZone}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        )}
                    </Paper>
                </Grid >
            </Grid >
        </>
    );
}

export default WithdrawsBroker;