import React from 'react'
import {
    Grid,
} from "@mui/material";

//components
import PageHeader from '../components/common/PageHeader';
import MerchantTable from '../components/merchant/MerchantTable';
import { SaveButton } from '../Theme';

function Merchant() {
    return (
        <>
            <Grid >
                <PageHeader
                    title="ข้อมูลร้านค้า"
                    TextButton={
                        <SaveButton
                            variant="contained"
                            sx={{
                                display: { xs: "unset", sm: "none", md: "none" },
                                width: { xs: "100%" },
                                fontSize: { xs: "0.65rem", sm: "1rem", md: "1.125rem" },
                            }}
                        >
                            + เพิ่มร้านค้า
                        </SaveButton>
                    }
                    path="Merchant/merchantRecord"
                />
                <MerchantTable />
            </Grid >
        </>
    )
}

export default Merchant;